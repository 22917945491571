
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'stipend-eligibility-check',
  components: {
    //Datatable,
  },
  data() {
    return {
      moment: '' as any,
      stipendEligibleSTatus: 0 as any,
      api_url: '',
      trainee: {
        registration_number: '',
        reference_number: '',
        nid: '',
        bcn: '',
      },
      traineeInfo: {
        course_info: '',
      } as any,
      load: false,
      showTraineeInfo: false,
      firstrule: 0 as any,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async getTraineeInfo() {
      this.load = true;
      if (
        this.trainee.registration_number ||
        this.trainee.reference_number ||
        this.trainee.nid ||
        this.trainee.bcn
      ) {
        await ApiService.get(
          'trainee/search?registration_number=' +
            this.trainee.registration_number +
            '&reference_number=' +
            this.trainee.reference_number +
            '&nid=' +
            this.trainee.nid +
            '&bcn=' +
            this.trainee.bcn
        )
          .then((response) => {
            this.load = false;
            // stipend logic check

            this.traineeInfo = response.data.data;
            let firstrule = 0;
            this.stipendEligibleSTatus = 0;

            if (
              this.traineeInfo?.course_info?.isStipendCourse == 1 &&
              this.traineeInfo?.attendance_percentage >= 80
            ) {
              if (
                this.traineeInfo?.course_info?.isStipendCourse == 1 &&
                this.traineeInfo?.attendance_percentage >= 80 &&
                (this.traineeInfo?.ethnic_group != 1 ||
                  this.traineeInfo?.is_physically_challenged == 'Yes' ||
                  this.traineeInfo?.gender == 'Female')
              ) {
                console.log(
                  'first rule e dukce + baki condition match 2nd phase '
                );

                this.stipendEligibleSTatus = 1;
              } else {
                if (
                  this.traineeInfo?.course_info?.isStipendCourse == 1 &&
                  this.traineeInfo?.attendance_percentage >= 80 &&
                  this.traineeInfo?.father_annual_income /
                    ((+this.traineeInfo?.number_of_siblings + 1) * 365) <
                    200 &&
                  this.traineeInfo?.per_address != '' &&
                  this.traineeInfo?.income_certificate?.file_name != null
                ) {
                  this.stipendEligibleSTatus = 1;
                  console.log(
                    'first rule e dukce + baki condition match 3rd phase '
                  );
                }
              }
            }

            //console.log(this.stipendEligibleSTatus);
            this.showTraineeInfo = true;
          })
          .catch(({ response }) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Searching Parameter Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
        this.load = false;
      }
    },
  },
});
